import React from 'react';
import { SocialIcon as SocialIconComponent } from 'react-social-icons';

import { Icon, InlineIcon } from '@iconify/react';
import tiktokIcon from '@iconify-icons/simple-icons/tiktok';
import { Box } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';


const SocialIcon = ({ url, bgColor, target = "_blank" }) => (
    /*<GatsbyLink to={url}>
      <Box
        sx={{
          height: 48,
          width: 48,
          marginLeft: [2, 3],
          opacity: 0.8,
          ':hover,:focus,.active': {
            opacity: 1,
          },
          p: [2]
        }}
      >
        <Icon
          icon={tiktokIcon}
          color={bgColor}
          height='100%'
          width='100%'
        />
      </Box>
    </GatsbyLink>*/

    <SocialIconComponent
      url={url}
      target={target}
      sx={{
        height: 48,
        width: 48,
        marginLeft: [2, 3],
        opacity: 0.8,
        ':hover,:focus,.active': {
          opacity: 1,
        },
      }}
      bgColor={bgColor}
    />

);

export default SocialIcon;
